import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useGlobalAptSelectorOpen, useGlobalCurrentHouse } from "../globalState"
import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import Button from "../components/Button/Button"
import ApartmentsList from "../components/ApartmentsList/apartmentsList"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import PageTitle from "../components/PageTitle/pageTitle"
import FactsList from "../components/FactsList/factsList"
import WindowDisplay from "../components/WindowDisplay/windowDisplay"

const GardsHuset = ({ data }) => {
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  const [, setCurrentHouse] = useGlobalCurrentHouse()

  const apartmentsData = data.allSanityApartment.nodes
  const seoTitle = apartmentsData[0].apartmentType.house.seoTitle
  const seoDescription = apartmentsData[0].apartmentType.house.seoDescription

  useEffect(() => {
    setCurrentHouse("gardshuset")
  }, [setCurrentHouse])

  return (
    <Layout backgroundColor="blue">
      <Seo title={seoTitle} description={seoDescription} />
      <PageTitle title="Gårdshuset" number={2} />
      <ImageTextBlock
        text={[
          `Det åtta våningar höga Gårdshuset kommer att torna upp sig bakom Porthusets anspråkslösa fasad. Mellan de båda byggnader bildas gemensam, grönskande plats med stark och skyddad gårdsatmosfär. Byggnadens spektakulära gestaltning har redan på skisstadiet väckt uppmärksamhet med sitt på samma gång rena och lekfulla formspråk. Byggnadens två – i djupled lätt förskjutna – element får en fasad i huggen natursten. Med pelare och balkar skapas ett rutmönster som ramar in lägenheternas heltäckande glasväggar. Fasaderna avslutas med en brant diagonal i höjd med de två översta våningarna. Häruppe finns också en gemensam takterrass.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={6}
        marginBottom="narrow"
      />

      <div style={{ position: "relative" }}>
        <StaticImage
          src="../images/bostadsvaljare.png"
          quality={95}
          layout="fullWidth"
          formats={["AUTO", "WEBP"]}
          alt="Bostadsväljare"
        />
        <div
          style={{
            position: "absolute",
            top: "calc(50% - 23px)",
            left: "calc(50% - 125px)",
          }}
        >
          <Button
            type="button"
            onClick={() => setAptSelectorOpen(true)}
            title="Öppna bostadsväljare"
          />
        </div>
      </div>

      <ImageTextBlock
        text={[
          `Även husets disposition är ensam i sitt slag. De totalt 35 lägenheterna är kvadratsmarta på 1-2 rum och kök. Gårdshusets målgrupp definieras inte utifrån ålder eller livssituation utan efter funktion och livsstil, delvis utifrån ett hållbarhetsperspektiv. En student och en pensionär kan därför bo granne med en mindre barnfamilj. Lägenheternas öppna atmosfär är starkt präglad av massivt ljus och rena arkitekturlinjer. Sällskapsytorna kan förvandlas till en terrass med glasräcke, genom att den övre halvan av den heltäckande glasväggen hissas ner elektroniskt. Tidlös design och genuina material kännetecknar interiören i allt från sobra badrum och smarta kök, till öppna golvytor i ek. Här finns allt du behöver för en aningen mer okomplicerad livsstil – mitt i stan.`,
        ]}
        imagePosition="right"
        offset={1}
        columns={6}
        marginBottom="narrow"
      />

      <FactsList
        facts={[
          { title: "Boarea", info: "20-35 kvm" },
          { title: "Rum", info: "1-2 rum och kök" },
          { title: "Pris", info: "1 700 000 kr – 4 100 000 kr" },
          { title: "Avgift", info: "1476-2098kr/mån" },
          { title: "Tillträde", info: "Prel. December 2024" },
          { title: "Antal lägenheter", info: "35" },
          { title: "Upplåtelseform", info: "Bostadsrätt" },
          { title: "Hiss", info: "Ja" },
          {
            title: "Uppvärmning",
            info: "FTX med 85% \nvärmeåtervinning",
          },
          { title: "Adress", info: "Tredje Långgatan 26" },
        ]}
      />

      <ImageTextBlock
        preHeading="Byggnaden"
        text={[
          `Gestaltningen av den nya bebyggelsen på gården tar sin utgångspunkt i en strävan efter gedigna bostadskvalitéer och material som är förankrade i platsen och åldras med värdighet. Fasaden utförs i en huggen natursten som samspelar med de grovhuggna naturstenssocklar som präglar de nationalromantiska byggnaderna i kv Barken. Fasaden är indelad i en klassisk tektonisk ordning som skänker byggnadsvolymen ett lugn och en sammanhållen gestalt. Genom att förankra fasaden med bärande och burna element blir den läsbar och får en mänsklig skala som relaterar till den västerländska arkitekturens ursprung. Mötet mellan pelare och balk markeras med en plåtkassett som döljer ventilationen och blir som ett smycke på fasaden. Motoriserade vertikala skjutfönster från golv till tak gör det möjligt att på ett självklart sätt förvandla den enskilda bostadens fasad till en balkong och ger byggnaden en säregen öppenhet. Den regelbundna stenfasaden ger byggnaden tyngd och värdighet som låter volymen landa på ett självklart sätt i den omgivande stenstaden.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={5}
        image={
          <StaticImage
            src="../images/gardshuset-fasad.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Byggnaden"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ImageTextBlock
        text={[
          `Planen är organiserad i lager med trapphus och trapphall i den mörka kärnan och lägenheter som sträcker sig ut mot ljuset. De enskilda bostadslägenheterna är organiserade efter samma princip där de funktioner som inte kräver direkt dagsljus, såsom hall och badrum ligger djupt i byggnadskroppen och vistelserummen i de ljusaste delarna närmast fasaden. De stora glaspartierna släpper in generöst med dagsljus och möjliggör ljusa och luftiga lägenheter även mot norrsidan.`,
          `Bostäderna utgörs av yteffektiva lägenheter, med en avskild sovplats och de sociala funktionerna närmast glaspartierna. De generösa ljusinsläppen ger lägenheten en känsla av rymd och ljus. Bostäder kompletteras med en gemensam terrass på översta våningen, med utsikt över Linnéstadens takåsar.`,
        ]}
        imagePosition="right"
        offset={2}
        columns={4}
        image={
          <StaticImage
            src="../images/gardshuset-terrass.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Terrassen"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <WindowDisplay />

      <ImageTextBlock
        preHeading="Platsen"
        text={[
          `Tredje Långgatan kännetecknas av en brokig kvarterstadsbebyggelse som varierar stort mellan höga och låga byggnadsvolymer. Rester av småskalig industribebyggelse samsas med robusta tegelbyggnader och putsfasader, från årtiondena kring förra sekelskiftet. Byggnaderna möter gaturummet med grovhuggna naturstenssocklar eller rusticerade entrévåningar. Gårdsfasaderna präglas av putsfasader i ljusa kulörer med mörkare fönsterpartier.`,
          `På fastigheten låg ursprungligen en smidesverkstad som uppfördes 1902. Bebyggelsen utgjordes av själva verkstadsbyggnaden mitt på gården och en representativ kulissfasad mot gatan. Dessa två byggnader knöts ihop av ett glastak som täckte själva gårdsytan vilken var en del av verkstaden.`,
        ]}
        imagePosition="left"
        offset={1}
        columns={5}
        image={
          <StaticImage
            src="../images/omrade.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Platsen"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ImageTextBlock
        preHeading="Gården"
        text={[
          `På gårdsmarken anordnas privata uteplatser åt lägenheterna i bottenvåningen. Gårdsytan gestaltas som ett mindre privat kvarterstorg. Markbeläggningen består av tumlad betongsten och planteringen i mitten tillåts få höjd och volym som på ett naturligt sätt skapar intima rum för de privata uteplatserna.`,
          `Gårdshuset och porthuset har sina egna tydliga gestalter men samspelar tydligt i kulörsättning och proportioner.`,
        ]}
        imagePosition="right"
        offset={2}
        columns={4}
        image={
          <StaticImage
            src="../images/situationsplan-innergard-landscape.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Gården"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <ApartmentsList data={apartmentsData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityApartment(
      filter: {
        apartmentType: { house: { slug: { current: { eq: "gardshuset" } } } }
      }
    ) {
      nodes {
        slug {
          current
        }
        _id
        area
        floor
        price
        aptId
        aptNumber
        status
        hireCharge
        planArrangement {
          asset {
            gatsbyImageData
          }
        }
        title
        apartmentType {
          type
          heroImage {
            asset {
              url
            }
          }
          house {
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`

export default GardsHuset
