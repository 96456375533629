import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { H2 } from "../../styles/typography"
import { Wrapper, ListHeader, RowItem, ApartmentInfo, List } from "./styles"
import Arrow from "../../images/arrow.svg"
import { getTotalRooms } from "../../utils/getTotalRooms"

const ApartmentsList = ({ data }) => {
  const listHeaderItems = [
    "Bostad",
    "Lgh. nr.",
    "Rum",
    "Vån",
    "Boarea",
    "Pris",
    "Avgift",
    // "Status",
  ]

  const apartments = []

  data.forEach((apartment, index) => {
    const object = {
      title: apartment.title ? apartment.title : "",
      aptNo: apartment.aptNumber ? apartment.aptNumber : "",
      room: getTotalRooms(apartment.apartmentType.type),
      floor: apartment.floor ? apartment.floor : "",
      area: apartment.area ? apartment.area + " m2" : "",
      price: apartment.price
        ? apartment.price
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + " kr"
        : "",
      charge: apartment.hireCharge
        ? apartment.hireCharge
            .toString()
            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + " kr"
        : "",
      // sold:
      // apartment.status === 2
      //   ? "Såld"
      //   : apartment.status === 1
      //   ? "Bokad"
      //   : "Ledig",
      // status: apartment.status,
      slug: apartment.slug.current,
      aptId: apartment.aptId,
    }
    apartments.push(object)
  })

  apartments.sort((a, b) => {
    if (a.aptId < b.aptId) return -1
    if (a.aptId > b.aptId) return 1
    return 0
  })

  return (
    <Wrapper>
      <H2>Lägenheter</H2>
      <List>
        <ListHeader>
          {listHeaderItems.map((title, index) => (
            <RowItem key={title} index={index}>
              {title}
            </RowItem>
          ))}
        </ListHeader>
        {apartments.map(apartment => (
          <AniLink
            key={apartment.aptId}
            cover
            bg="#C4C4C4"
            to={`/${apartment.slug}`}
          >
            <ApartmentInfo>
              {Object.entries(apartment).map(([key, value], index) => {
                if (key !== "slug" && key !== "aptId" && key !== "status") {
                  return (
                    <RowItem
                      showBefore={key === "sold"}
                      isApartmentSold={apartment.status}
                      key={index}
                      index={index}
                    >
                      {value}
                    </RowItem>
                  )
                }
                return null
              })}
              <Arrow />
            </ApartmentInfo>
          </AniLink>
        ))}
      </List>
    </Wrapper>
  )
}

ApartmentsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
}

export default ApartmentsList
