import React from "react"
import PropTypes from "prop-types"
import { H1, H3, H4 } from "../../styles/typography"
import { TitleBar, Title, Dot, AptNavigation, ButtonsWrapper } from "./styles"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Arrow from "../../images/arrow.svg"
import Button from "../Button/Button"
import { useGlobalAptSelectorOpen } from "../../globalState"

const PageTitle = ({ title, number, isApartment }) => {
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  const data = useStaticQuery(graphql`
    query {
      allSanityApartment {
        edges {
          node {
            slug {
              current
            }
            title
          }
        }
      }
    }
  `)

  const apartments = data.allSanityApartment.edges

  apartments.sort((a, b) => {
    if (a.node.title < b.node.title) return -1
    if (a.node.title > b.node.title) return 1
    return 0
  })

  const currentIndex = apartments.findIndex(
    apartment => apartment.node.title === title
  )
  const prevApt = apartments[currentIndex - 1] ?? false
  const nextApt = apartments[currentIndex + 1] ?? false

  return (
    <TitleBar>
      <Title>
        <Dot house={number}>
          <H3>{number}</H3>
        </Dot>
        <H1 size="large">{title}</H1>
      </Title>
      {isApartment && (
        <ButtonsWrapper>
          <Button
            type="button"
            onClick={() => setAptSelectorOpen(true)}
            title="Öppna bostadsväljare"
            isInverted
          />
          <AptNavigation>
            {prevApt && (
              <AniLink
                cover
                bg="#C4C4C4"
                to={`/${prevApt.node.slug.current}`}
                direction="right"
              >
                <Arrow />
                <H4>{prevApt.node.title}</H4>
              </AniLink>
            )}
            {nextApt && (
              <AniLink cover bg="#C4C4C4" to={`/${nextApt.node.slug.current}`}>
                <H4>{nextApt.node.title}</H4>
                <Arrow />
              </AniLink>
            )}
          </AptNavigation>
        </ButtonsWrapper>
      )}
    </TitleBar>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
  isApartment: PropTypes.bool,
}

PageTitle.defaultProps = {
  isApartment: false,
}

export default PageTitle
