import styled from "styled-components"
import { main } from "../../styles/theme"

export const Wrapper = styled.section`
  padding: 60px 0 100px;
  @media ${main.breakpoints.medium} {
    padding: 100px 0 150px;

    > h2 {
      margin-bottom: 66px;
    }
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  > a {
    width: fit-content;
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray}`};

    &:last-of-type {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    }
    &:hover {
      opacity: 1;
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.black}`};
      border-top: ${({ theme }) => `1px solid ${theme.colors.black}`};

      + a {
        border-top: none;
      }
    }
    @media ${main.breakpoints.large} {
      width: 100%;
    }
  }
`

export const ListHeader = styled.div`
  display: grid;
  height: 60px;
  align-items: center;
  grid-template-columns: repeat(12, minmax(50px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 0px;
`

export const ApartmentInfo = styled.div`
  display: grid;
  height: 60px;
  align-items: center;
  cursor: pointer;
  grid-template-columns: repeat(12, minmax(50px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 0px;

  > svg {
    grid-column-start: 12;
    justify-self: flex-end;
    align-self: center;
  }
`

export const RowItem = styled.div`
  grid-column-start: ${({ index }) =>
    index >= 4 ? index + index - 3 : index + 1};
  width: max-content;
  position: relative;
  margin-left: ${({ showBefore }) => (showBefore ? "20px" : "0")};

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: ${({ showBefore }) => (showBefore ? "block" : "none")};
    background-color: ${({ isApartmentSold, theme }) =>
      isApartmentSold === 2
        ? theme.colors.validationRed
        : isApartmentSold === 1
        ? theme.colors.validationOrange
        : theme.colors.validationGreen};
    position: absolute;
    left: -20px;
    top: 6px;
  }
`
