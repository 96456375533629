import styled from "styled-components"
import { flexCenter } from "../../styles/mixins"
import { main } from "../../styles/theme"

export const TitleBar = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px 0 40px;

  @media ${main.breakpoints.large} {
    align-items: center;
    flex-direction: row;
  }
`

export const Title = styled.section`
  display: flex;
  align-items: center;
`

export const Dot = styled.span`
  ${flexCenter}
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
  margin-right: 21px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  @media ${main.breakpoints.large} {
    width: 72px;
    height: 72px;
  }

  > h3 {
    color: ${({ theme, house }) =>
      house === 1 ? theme.colors.yellow : theme.colors.blue};
  }
`

export const AptNavigation = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-top: 40px;

  @media ${main.breakpoints.large} {
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: 0;
  }

  > a {
    display: flex;
    align-items: center;
    padding: 10px 0 6px;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.7;
    }

    &:first-child:not(:last-child) {
      margin-right: 10px;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  > a h4 {
    margin: 0 14px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  > button {
    margin-top: 40px;
  }
  @media ${main.breakpoints.large} {
    align-items: flex-end;

    > button {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`
